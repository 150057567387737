html {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
}

body {
    margin: 0px;
    padding: 0px;
    font-family: "Open Sans", sans-serif;
    color: #464646;
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
    width: 100%;
}

.Login-Label {
    width: 100%;
    display: block;
    font-size: 30px;
    color: #555555;
    line-height: 1.2;
    text-align: center;
}

.finished {
    color: #61bd4f;
}

/* Set padding to keep content from hitting the edges */

.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

/* 
   Override the default bootstrap behavior where horizontal description lists 
   will truncate terms that are too long to fit in the left column 
*/

.dl-horizontal dt {
    white-space: normal;
}

.input-padding {
    margin-right: 10px;
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 40px;
    color: #338ab7;
}

h4 {
    font: 300 40px/40px "Open Sans", sans-serif;
    color: #338ab7;
}

h5 {
    font-size: 20px;
    color: #464646;
}

h6 a {
    font-weight: 400;
    margin: 26px 0 7px;
    color: #464646;
}

.center {
    text-align: center;
}

.UpperCase {
    text-transform: uppercase;
}

.bg-content {
    padding-top: 20px;
    opacity: 1;
}

.container {
    opacity: 1;
}

.hide {
    display: none;
}

a:focus {
    text-decoration: none !important;
}

.hiddenElement {
    display: none !important;
}

.login-row {
    padding-top: 5px;
}

.imgDisplay {
    height: 50px;
    width: 50px;
    visibility: visible;
    border-radius: 50%;
    left: -280px;
    position: absolute;
}

a:hover {
    text-decoration: none;
}

.row{
    margin-right: 0px !important;
}